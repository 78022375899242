import PageRender from '../../models/PageRender';
import Component from 'vue-class-component';
import Site from '../../models/Site';
import { siteService, facilityService } from '@/main';
import Facility from '../../models/Facility';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import FacilityGroup from '../../models/FacilityGroup';
import { sitesModule } from '@/store/modules/site';

@Component
export default class SiteFacilities extends PageRender {
    public mode: string = '';
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public facilities: Facility[] = null;
    public facilityGroups: FacilityGroup[] = null;
    public newFacility: Facility = { name: '', isSystemFacility: false, group: { facilityGroupId: null } } as Facility;
    public newFacilityGroup: FacilityGroup = { name: '' } as FacilityGroup;
    public facilityToDelete: Facility = {} as Facility;
    public facilityGroupToDelete: FacilityGroup = { items: [] } as FacilityGroup;
    public sitesInFacility: Site[] = [] as Site[];
    public isLoadingSitesInFacility: boolean = false;
    public isLoadingItemsInFacilityGroup: boolean = false;
    public systemScope: boolean = false;
    public tabIndex: number = 0;

    public facilityGridColumns = [
        { field: 'name', title: 'Naam' },
        { title: 'Groep', cell: this.renderFacilityGroupName, width: '200px' },
        { field: 'isSystemFacility', cell: this.renderSystemFacility, title: 'Systeem faciliteit', filterCell: 'boolean' },
        { title: 'Acties', cell: this.renderFacilityActions, width: '150px' },
    ];

    public facilityGroupGridColumns = [
        { field: 'name', title: 'Naam' },
        { title: 'Acties', cell: this.renderFacilityGroupActions, width: '150px' },
    ];

    public $refs!: {
        facilityModal: BModal;
        facilityGroupModal: BModal;
        deleteFacilityModal: BModal;
        deleteFacilityGroupModal: BModal;
    };

    public async created() {
        await this.initFeatureBase();
    }

    public async initFeatureBase() {
        this.systemScope = this.$route.name === 'facilities';
        this.mode = this.$route.params.mode;
        await sitesModule.fetchSitesIfNeeded();

        this.facilities = await this.getFacilities();
        this.facilityGroups = await this.getFacilityGroups();
        this.facilityGroups.forEach((group) => {
            group.items = this.facilities.filter((facility) => facility.group.facilityGroupId === group.facilityGroupId);
        });

        if (this.$route.query.group) {
            this.tabIndex = this.facilityGroups.findIndex((x) => x.facilityGroupId === parseInt(this.$route.query.group as string, 10));

            if (this.$route.query.facility) {
                this.editFacility(this.facilities.find((x) => x.facilityId === parseInt(this.$route.query.facility as string, 10)));
            }
        }

        this.isLoading = false;
    }

    public get sites() {
        return sitesModule.sites;
    }

    public async getFacilities() {
        const [err, response] = await to<AxiosResponse<Facility[]>>(
            facilityService.getFacilities(this.systemScope ? null : this.siteKey, this.systemScope),
        );
        if (err) {
            this.showFailedResponse('Mislukt om faciliteiten op te halen', err);
        }

        return response.data || [];
    }

    public async getFacilityGroups() {
        const [err, response] = await to<AxiosResponse<FacilityGroup[]>>(facilityService.getFacilityGroups(this.systemScope ? null : this.siteKey));

        if (err) {
            this.showFailedResponse('Mislukt om faciliteitgroepen op te halen', err);
        }

        return response.data || [];
    }

    // Render
    public renderFacilityActions(h: any, a, row): any {
        const item = row.dataItem as Facility;
        if (item.isSystemFacility && !this.systemScope) {
            return h('td', '');
        }

        const actions = [
            { title: 'Bewerk', function: this.editFacility },
            { title: 'Verwijder', function: this.confirmDeleteFacility },
        ];

        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderSystemFacility(h: any, a: any, row: any): any {
        return h('td', [row.dataItem.isSystemFacility ? 'Ja' : 'Nee']);
    }

    public renderFacilityGroupActions(h: any, a, row): any {
        const item = row.dataItem as FacilityGroup;

        if (!this.systemScope) {
            return h('td', '');
        }

        const actions = [
            { title: 'Bewerk', function: this.editFacilityGroup },
            { title: 'Verwijder', function: this.confirmDeleteFacilityGroup },
        ];

        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderFacilityGroupName(h: any, a, row): any {
        const name = row && row.dataItem ? row.dataItem.group.name : '';
        return h('td', name);
    }

    // Modals
    public openAddFacilityModal(group) {
        this.newFacility = { name: '', isSystemFacility: false, group } as Facility;
        this.newFacility.isSystemFacility = this.systemScope;

        this.$refs.facilityModal.show();
    }

    public openAddFacilityGroupModal() {
        this.newFacilityGroup = new FacilityGroup();
        this.$refs.facilityGroupModal.show();
    }

    public editFacility(item) {
        this.newFacility = item as Facility;
        this.newFacility.group = item.group;
        this.newFacility.group.items = null;

        this.$refs.facilityModal.show();
    }

    public editFacilityGroup(item) {
        this.newFacilityGroup = item as FacilityGroup;
        this.$refs.facilityGroupModal.show();
    }

    public async confirmDeleteFacility(item) {
        this.facilityToDelete = item as Facility;
        this.$refs.deleteFacilityModal.show();

        this.isLoadingSitesInFacility = true;
        this.sitesInFacility = await this.getSitesWithFacility(this.facilityToDelete);
        this.isLoadingSitesInFacility = false;
    }

    public async getSitesWithFacility(facility: Facility) {
        const [err, response] = await to(siteService.getSiteIdsWithFacility(facility));
        if (err) {
            this.showFailedResponse(`Mislukt om parken op te halen met faciliteit ${facility.name}.`, err);
        }
        const siteIds = response.data as [];
        const sites = [];
        siteIds.forEach((id) => {
            const site = this.sites.find((s: Site) => {
                return s.siteId === id;
            });
            sites.push(site);
        });
        return sites as Site[];
    }

    public cancelDeleteFacility() {
        this.facilityToDelete = {} as Facility;
        this.$refs.deleteFacilityModal.hide();
    }

    public async confirmDeleteFacilityGroup(item) {
        this.facilityGroupToDelete = item;
        this.$refs.deleteFacilityGroupModal.show();

        this.isLoadingItemsInFacilityGroup = true;
        const [err, response] = await to(facilityService.getGroupFacilities(item.facilityGroupId));
        if (err) {
            return this.clearAndShowError('Mislukt om faciliteit groepen op te halen', err);
        }
        this.facilityGroupToDelete.items = response.data;
        this.isLoadingItemsInFacilityGroup = false;
    }

    public cancelDeleteFacilityGroup() {
        this.facilityGroupToDelete = {} as FacilityGroup;
        this.$refs.deleteFacilityGroupModal.hide();
    }

    // Create, update and delete
    public async upsertFacility() {
        const self = this;
        const facilityGroup = this.facilityGroups.find((group: FacilityGroup) => {
            return group.facilityGroupId === self.newFacility.group.facilityGroupId;
        });

        const [err, result] = await to<AxiosResponse<Facility>>(
            facilityService.createOrUpdateFacility(this.newFacility, facilityGroup.facilityGroupId),
        );

        if (err) {
            return this.clearAndShowError('Mislukt om faciliteit op te slaan', err);
        }

        if (!this.newFacility.facilityId) {
            this.newFacility = result.data;
            this.newFacility.group = facilityGroup;
            this.facilities.push(this.newFacility);
        }

        this.clearAndShowSuccess('Faciliteit succesvol opgeslagen');
        this.$refs.facilityModal.hide();
    }

    public async upsertFacilityGroup() {
        const [err, result] = await to<AxiosResponse<FacilityGroup>>(facilityService.createOrUpdateFacilityGroup(this.newFacilityGroup));

        if (err) {
            return this.clearAndShowError('Mislukt om faciliteit groep op te slaan', err);
        }

        if (!this.newFacilityGroup.facilityGroupId) {
            this.newFacilityGroup = result.data;
            this.facilityGroups.push(this.newFacilityGroup);
        }
        this.clearAndShowSuccess('Faciliteit groep succesvol opgeslagen');
        this.$refs.facilityGroupModal.hide();
    }

    public async deleteFacility(item) {
        this.showPending(`Faciliteit <b>${item.name} verwijderen...</b>`);
        const [err, response] = await to(facilityService.deleteFacility(item.facilityId));
        if (err) {
            return this.clearAndShowError('Mislukt om faciliteit te verwijderen', err);
        }

        const index = this.facilities.indexOf(item);
        this.facilities.splice(index, 1);

        const facilities = this.facilities.slice(0);
        this.facilities.length = 0;
        this.facilities = facilities;

        this.$refs.deleteFacilityModal.hide();
        return this.clearAndShowSuccess(`Facility <b>${item.name}</b> deleted.`);
    }

    public async deleteFacilityGroup(item) {
        this.showPending(`Faciliteit groep <b>${item.name}</b> verwijderen...`);
        const [err, response] = await to(facilityService.deleteFacilityGroup(item));
        if (err) {
            return this.clearAndShowError('Mislukt om faciliteit groep te verwijderen', err);
        }

        const index = this.facilityGroups.indexOf(item);
        this.facilityGroups.splice(index, 1);

        this.$refs.deleteFacilityGroupModal.hide();
        return this.clearAndShowSuccess(`Facilitygroup <b>${item.name}</b> deleted.`);
    }
}
