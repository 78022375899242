import Product from './Product';
import PriceRuleGroup from './PriceRuleGroup';

export default class PriceRule {
    public isActive: boolean;
    public name: string;
    public priceRuleGroups: PriceRuleGroup[];
    public priceRuleId: number;
    public product: Product;
    public productId: number;
    public siteIds: number[];
}
